import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'components';
import { Checkbox } from '@smartplatform/ui';
import { EditColumnsPopup } from './editPopup';
import t from 'i18n';

import './toolbar.scss';

@inject('trendReportStore')
@observer
export class ReportToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.trendReportStore;
	}

	render() {
		const { isLoading, isEdit, onToggleEdit, styleEngineBtn, onChangeEngine, isSmoothed, onChangeSmoothed, printPdf } = this.store;

		const disabled = isEdit || isLoading;

		return (
			<div className='report-toolbar'>
				<div className='action-bar'>
					<div className='form-field'></div>
					<div className='actions'>
						<Button size='sm' disabled={disabled} onClick={onToggleEdit(true)} text={t('aircraft.tableReport.editReport')} />
						<Button size='sm' disabled={disabled} onClick={printPdf} text={t('downloadPDF')} />
						<div className='legend'>
							<div className={styleEngineBtn(1)} onClick={onChangeEngine(1)}>
								<div className='eng1button' />
								<span className='engText'>Двигатель-1</span>
							</div>
							<div className={styleEngineBtn(2)} onClick={onChangeEngine(2)}>
								<div className='eng2button' />
								<span className='engText'>Двигатель-2</span>
							</div>
						</div>
						{isEdit && <EditColumnsPopup />}
					</div>
				</div>
			</div>
		);
	}
}
