import store from 'client/store';
import { observable, toJS } from 'mobx';
import { DANGEROUS_LEVEL } from './constants';
import { endOfDay, format, startOfDay } from 'date-fns';
import t from 'i18n';
import jsPDF from 'jspdf';
import { createRef } from 'react';
import html2canvas from 'html2canvas';
import './robotoRegular';
import { CONFIGURATION_INCLUDE } from 'client/pages/planes/constants';
import { DAT_ACCESSIBLE_MANUFACTURER, XLSX_ACCESSIBLE_MANUFACTURER } from '../constants';
import { formatDate, stringToBoolean } from 'client/tools';
import _ from 'lodash';
import { generateColor } from 'client/tools/generateColor';
import debounce from 'lodash/debounce';
import { halfTimeDifferenceInSeconds } from 'client/tools/date/halfTimeDifferenceInSeconds';

export class graphicReportStore {
	@observable isLoading = false;
	@observable isInit = false;
	@observable isLeftSideLoading = false;
	@observable selectedParams = new Map();
	@observable charts = new Map();
	@observable aircraftParams = [];
	@observable engineParams = [];
	@observable aircraft = null;
	@observable startDate = null;
	@observable endDate = null;
	@observable flightPhase = {};
	@observable BUTTONS = [];
	@observable key = 0;
	@observable isBombardie = false;
	@observable fakeCofDelta = 0;
	@observable isChartsLoad = false;
	cofDelta = 0.02; // Значение от 0 до 1
	@observable colorsChart = {
		1: '#520000',
		2: '#1400f0',
		3: '#dc00f0',
		4: '#00e025',
	};
	@observable isSmoothed = false;

	pdfReportRef = createRef();
	chartRefArray = new Map();
	localStorage = store.local.aircraft.graphicReport;
	cache = new Map();
	isConvert = false;
	smoothingCoefficient = 0.2;

	// Селект конфигурации устанавливаемых двигателей
	enginesConfigurationItems = [];
	@observable configuration = null;
	configurations = new Set();

	constructor(aircraftId, aircraft) {
		this.aircraftId = aircraftId;
		this.aircraft = aircraft;
		this.init();
	}

	init = async () => {
		this.reloadCharts = debounce(this.reloadCharts, 500, { leading: false, trailing: true });
		if (store.mergedConfig?.smoothingCoefficient) this.smoothingCoefficient = store.mergedConfig.smoothingCoefficient;
		this.BUTTONS = await this.fetchButtons();
		this.flightPhase = this.BUTTONS[0];
		//this.flightPhase = this.localStorage.flightPhase ?? this.flightPhase;
		await await store.fetchDbServerConfig();
		await this.getEnginesConfiguration();
		await this.setIsConvert();
		await this.setParamsAndFlightPhase();
		await this.loadLocalStorage();
		this.isInit = true;
	};

	getEnginesConfiguration = async () => {
		const configurations = this.groupByProperty(await this.aircraft.configurations.find({ include: CONFIGURATION_INCLUDE, order: 'date asc' }), 'date');
		const plateEngines = await store.model.Engine.find({ where: { aircraftId: this.aircraftId } });

		const currentPlaneConf = plateEngines
			.sort((a, b) => a.enginePosition - b.enginePosition)
			.map((r) => r.id)
			.join('/');

		this.enginesConfigurationItems = Object.values(configurations).map((r, index) => {
			const conf = r
				.sort((a, b) => a.enginePosition - b.enginePosition)
				.map((r) => r.engineId)
				.join('/');
			return {
				id: index,
				values: r,
				label: r.map((_r) => _r.engine.serialNumber).join('/'),
				configuration: conf,
				current: conf === currentPlaneConf,
			};
		});

		if (this.enginesConfigurationItems.length)
			this.configuration = this.enginesConfigurationItems.find((r) => !!r.current) || this.enginesConfigurationItems[0];
	};

	onChangeConfiguration = async (item) => {
		this.configuration = item;
		this.reloadCharts();
	};

	onChangeDelta = (value) => {
		this.fakeCofDelta = value;
		this.reloadCharts();
	};

	onChangeColor = (key) => (value) => {
		this.colorsChart[key] = value;
		this.localStorage.colorsChart = this.colorsChart;
		store.local.save();
		this.reloadCharts();
	};

	onChangeSmoothed = () => {
		this.isSmoothed = !this.isSmoothed;
		this.reloadCharts();
	};

	reloadCharts = async () => {
		if (this.selectedParams.size) {
			const keys = Array.from(this.selectedParams.keys());
			const promises = keys.map((paramCode) => this.getChartsAndTitle(this.selectedParams.get(paramCode).param));
			this.charts = await this.getCharts(promises);
			for (const ref of this.chartRefArray.values()) {
				const option = ref.instance.getOptions();
				if (ref.chartRef) ref.chartRef.getEchartsInstance().setOption(option, true);
			}
		}
	};

	fetchButtons = async () => {
		const manufacturer = this.aircraft?.manufacturer?.name || '';
		this.isBombardie = DAT_ACCESSIBLE_MANUFACTURER.some((m) => m.includes(manufacturer?.toLowerCase()));
		this.isGSS = XLSX_ACCESSIBLE_MANUFACTURER.some((m) => m.includes(manufacturer?.toLowerCase()));

		const paramsFilter = (parameters) => {
			return parameters
				.filter((param) => {
					const isParamBombardie = DAT_ACCESSIBLE_MANUFACTURER.some((m) => m.includes(param.manufacturer?.name?.toLowerCase()));
					const isParamGSS = XLSX_ACCESSIBLE_MANUFACTURER.some((m) => m.includes(param.manufacturer?.name?.toLowerCase()));

					if (this.isBombardie) {
						return isParamBombardie;
					} else if (this.isGSS) {
						return isParamGSS;
					} else {
						return !isParamBombardie && !isParamGSS;
					}
				})
				.map((param) => param.code);
		};

		const TAKEOFF_AIRCRAFT_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'AircraftInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('TAKEOFF')
		);
		const TAKEOFF_ENGINE_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'EngineInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('TAKEOFF')
		);
		const CRUISE_AIRCRAFT_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'AircraftInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('CRUISE')
		);
		const CRUISE_ENGINE_PARAMETER_NAMES = (
			await store.model.Parameter.find({ where: { modelName: 'EngineInput' }, include: ['phases', 'manufacturer'] })
		).filter((param) =>
			param
				.phases()
				?.map((phase) => phase.name)
				?.includes('CRUISE')
		);

		const phase = this.isBombardie ? ['CRUISE'] : ['TAKEOFF', 'CRUISE'];
		return phase.map((flightPhaseName) => {
			let fields = {};
			if (flightPhaseName === 'TAKEOFF') {
				fields = {
					phaseName: 'TAKEOFF',
					aircraftParams: [...paramsFilter(TAKEOFF_AIRCRAFT_PARAMETER_NAMES)],
					engineParams: [...paramsFilter(TAKEOFF_ENGINE_PARAMETER_NAMES)],
				};
			} else if (flightPhaseName === 'CRUISE') {
				fields = {
					phaseName: 'CRUISE',
					aircraftParams: [...paramsFilter(CRUISE_AIRCRAFT_PARAMETER_NAMES)],
					engineParams: [...paramsFilter(CRUISE_ENGINE_PARAMETER_NAMES)],
				};
			}

			return fields;
		});
	};

	setIsConvert = async () => {
		this.isConvert = stringToBoolean(store.mergedConfig?.isConverKgToLbs || 'false');
	};

	setParamsAndFlightPhase = async () => {
		const { aircraftParams, engineParams } = this.flightPhase;

		if (!this.cache.has(this.flightPhase.phaseName)) {
			this.isLeftSideLoading = true;
			this.isLoading = true;
			const currentFlightPhase = await this.fetchFlightPhase();

			const [[_engineParams, engineParamValues], [_aircraftParams, aircraftParamValues]] = await Promise.all([
				this.fetchParams(engineParams, currentFlightPhase),
				this.fetchParams(aircraftParams, currentFlightPhase),
			]);
			const engineParamsWitchZT49_M = this.addZT49_M(_engineParams);

			this.isLeftSideLoading = false;
			this.isLoading = false;
			this.cache.set(this.flightPhase.phaseName, {
				engineParams: engineParamsWitchZT49_M,
				aircraftParams: _aircraftParams,
				flightPhaseData: currentFlightPhase,
				parameterValues: engineParamValues.concat(aircraftParamValues),
			});
		}

		let _engineParams = this.cache.get(this.flightPhase.phaseName).engineParams;
		const engineParamsWitchZT49_M = this.addZT49_M(_engineParams);
		this.engineParams = engineParamsWitchZT49_M;
		this.aircraftParams = this.cache.get(this.flightPhase.phaseName).aircraftParams;
	};

	addZT49_M = (_engineParams) => {
		const ZT49Index = _engineParams.findIndex((r) => r.code === 'ZT49');
		const ZT49_MExists = _engineParams.some((r) => r.code === 'ZT49_M');
		if (ZT49Index !== -1 && !ZT49_MExists) {
			_engineParams.splice(ZT49Index + 1, 0, { ..._engineParams[ZT49Index], code: 'ZT49_M' });
		}
		return _engineParams;
	};

	onDateChange = (prop) => async (object) => {
		const { value, e } = object;
		this[prop] = value;
		this.localStorage[prop] = value;
		store.local.save();

		this.reloadCharts();
	};

	getCharts = async (promises) => {
		this.isLoading = true;

		const results = await Promise.all(promises);

		const charts = new Map();
		results.forEach((item) => {
			item.isConvert = this.isConvert;
			charts.set(item.param.code, item);
		});

		this.isLoading = false;

		return charts;
	};

	get getFilters() {
		const where = {
			aircraftId: this.aircraft.id,
			phaseId: this.cache.get(this.flightPhase.phaseName).flightPhaseData.id,
			and: [],
		};

		if (this.startDate) {
			where.and.push({
				flightTime: {
					gte: startOfDay(this.startDate),
				},
			});
		}

		if (this.endDate) {
			where.and.push({
				flightTime: {
					lte: endOfDay(this.endDate),
				},
			});
		}

		/*if (this.configuration) {
			where.and.push({ or: this.configuration.values.map((r) => ({ engineId: r.engineId, enginePosition: r.enginePosition })) });
		}*/

		return where;
	}

	onAircraftChange = async (value) => {
		this.aircraft = value;

		const promises = Array.from(this.selectedParams.keys())
			.map((key) => this.getChartsAndTitle(this.selectedParams.get(key).param))
			.filter(Boolean);

		this.charts = await this.getCharts(promises);

		await this.init();
	};

	fetchParams = async (paramCodeNames, currentFlightPhase) => {
		const params = await store.model.Parameter.find({
			where: {
				code: {
					inq: paramCodeNames,
				},
			},
		});

		const promises = [];
		for (const param of params) {
			promises.push(
				store.model.ParameterLibrary.find({
					where: {
						parameterId: param.id,
						phaseId: currentFlightPhase.id,
					},
					limit: 1,
				})
			);
		}

		const paramValues = (await Promise.all(promises)).map((item) => item[0]);

		return [params, paramValues];
	};

	fetchFlightPhase = async () => {
		const selectedPhase = (
			await store.model.Phase.find({
				where: {
					name: this.flightPhase.phaseName,
				},
			})
		)[0];

		return selectedPhase;
	};

	selectAll = async (params = []) => {
		const promises = params
			.map((param) => {
				if (!this.selectedParams.has(param.code)) {
					this.selectedParams.set(param.code, { param });
					const selectedParams = this.localStorage.selectedParams[this.flightPhase.phaseName];
					this.localStorage.selectedParams[this.flightPhase.phaseName] = selectedParams ? selectedParams : {};
					this.localStorage.selectedParams[this.flightPhase.phaseName][param.code] = param;
					return this.getChartsAndTitle(param);
				}
			})
			.filter(Boolean);

		store.local.save();

		if (promises.length) {
			const charts = await this.getCharts(promises);

			charts.forEach((chart) => {
				this.charts.set(chart.param.code, chart);
			});
		}
	};

	removeAll = (params = []) => {
		params.forEach((param) => {
			this.selectedParams.delete(param.code);
			this.charts.delete(param.code);
			delete this.localStorage.selectedParams[this.flightPhase.phaseName]?.[param.code];
			delete this.localStorage.selectedParams[this.flightPhase.phaseName]?.[param.id];
		});

		store.local.save();
	};

	onParamChange = (param) => async (e) => {
		if (e.checked) {
			this.isLoading = true;
			this.selectedParams.set(param.code, { param });
			const selectedParams = this.localStorage.selectedParams[this.flightPhase.phaseName];
			this.localStorage.selectedParams[this.flightPhase.phaseName] = selectedParams ? selectedParams : {};
			this.localStorage.selectedParams[this.flightPhase.phaseName][param.code] = param;

			const result = await this.getChartsAndTitle(param);
			this.charts.set(param.code, result);

			this.isLoading = false;
		} else {
			this.selectedParams.delete(param.code);
			this.charts.delete(param.code);
			delete this.localStorage.selectedParams[this.flightPhase.phaseName]?.[param.code];
			delete this.localStorage.selectedParams[this.flightPhase.phaseName]?.[param.id];
		}

		store.local.save();
	};

	getChartsAndTitle = async (param) => {
		let currentParam = {
			param,
			title: this.getParameterName(param),
		};

		const regexp = new RegExp(/^engine/gi);
		let getSeries = this.getAircraftSeries;

		if (regexp.test(param.modelName)) {
			getSeries = this.getEngineSeries;
		}

		const filters = this.getFilters;
		let series = await getSeries({ ...filters, parameterId: param.id });
		const { parameterValues } = this.cache.get(this.flightPhase.phaseName);

		let { minLimit, maxLimit } = parameterValues.find((_pl) => _pl?.parameterId === param.id) ?? {};
		if (param.code === 'ZT49_M') {
			for (const itemSeries of series) {
				const dataZT49_M = itemSeries.data;
				for (const itemData of dataZT49_M) {
					itemData[1] = ((maxLimit - parseFloat(itemData[1])) / 3.5)?.toFixed(3);
				}
			}
			maxLimit = '';
			minLimit = '';
		}

		let markLine = {
			data: [
				[
					{
						x: 0,
						yAxis: maxLimit,
						lineStyle: {
							color: '#000',
							type: 'solid',
						},
					},
					{
						x: '100%',
						yAxis: maxLimit,
					},
				],

				[
					{
						x: 0,
						yAxis: minLimit,
						lineStyle: {
							color: '#000',
							type: 'solid',
						},
					},
					{
						x: '100%',
						yAxis: minLimit,
					},
				],
			],
		};
		//для параметров вибрации
		if (['ZVB2R', 'ZVB1F', 'ZVB2F', 'ZVB1R'].includes(param.code)) {
			markLine.data = [
				...markLine.data,
				[
					{
						x: 0,
						yAxis: maxLimit,
						lineStyle: {
							color: '#000',
							type: 'solid',
						},
					},
					{
						x: '100%',
						yAxis: maxLimit,
					},
				],
				[
					{
						x: 0,
						yAxis: maxLimit * DANGEROUS_LEVEL.medium.coefficient,
						lineStyle: {
							color: '#000',
							type: [5, 3],
							dashOffset: 1,
						},
					},
					{
						x: '100%',
						yAxis: maxLimit * DANGEROUS_LEVEL.medium.coefficient,
					},
				],
				[
					{
						x: 0,
						yAxis: maxLimit * DANGEROUS_LEVEL.low.coefficient,
						lineStyle: {
							color: '#000',
							type: [5, 10],
							dashOffset: 5,
						},
					},
					{
						x: '100%',
						yAxis: maxLimit * DANGEROUS_LEVEL.low.coefficient,
					},
				],
				[
					{
						x: 0,
						yAxis: minLimit * DANGEROUS_LEVEL.low.coefficient,
						lineStyle: {
							color: '#000',
							type: [5, 10],
							dashOffset: 5,
						},
					},
					{
						x: '100%',
						yAxis: minLimit * DANGEROUS_LEVEL.low.coefficient,
					},
				],
				[
					{
						x: 0,
						yAxis: minLimit * DANGEROUS_LEVEL.medium.coefficient,
						lineStyle: {
							color: '#000',
							type: [5, 3],
							dashOffset: 1,
						},
					},
					{
						x: '100%',
						yAxis: minLimit * DANGEROUS_LEVEL.medium.coefficient,
					},
				],
				[
					{
						x: 0,
						yAxis: minLimit,
						lineStyle: {
							color: '#000',
							type: 'solid',
						},
					},
					{
						x: '100%',
						yAxis: minLimit,
					},
				],
			];
		}

		series = series.map((item) => {
			return {
				...item,
				markLine,
			};
		});

		currentParam = {
			...currentParam,
			min: minLimit,
			max: maxLimit,
			series,
		};

		return currentParam;
	};

	getAircraftSeries = async (where) => {
		const aircraftInputs = await store.model.AircraftInput.find({
			where,
			order: 'flightTime asc',
		});

		let series = new Array(1).fill({
			type: 'line',
			data: [],
		});

		if (aircraftInputs.length) {
			const yAxisData = this.setYAxisData(aircraftInputs);
			series = [
				{
					type: 'line',
					data: yAxisData,
					name: `${t('aircraft.title')} ${this.aircraft.serialNumber}`,
				},
			];
		}

		return series;
	};

	getEngineSeries = async (where) => {
		const engineInputs = await store.model.EngineInput.find({
			where,
			order: ['flightTime asc', 'enginePosition asc'],
			include: [{ relation: 'engine', scope: { fields: ['serialNumber'] } }],
		});

		const startDate = _.min(engineInputs.map((r) => r.flightTime));
		const endDate = _.max(engineInputs.map((r) => r.flightTime));
		const deltaSeconds = halfTimeDifferenceInSeconds(startDate, endDate);
		const _deltaSeconds = deltaSeconds * (this.cofDelta * (this.fakeCofDelta / 5));

		let series = [];

		for (let i = 0; i < this.aircraft.family.numberEngines; i++) {
			series.push({
				type: 'line',
				name: `${t('engine.title')} ${i + 1}`,
				data: [],
				itemStyle: {
					color: this.colorsChart[i + 1],
				},
			});
		}

		let rectangles = [];

		if (engineInputs.length > 0) {
			const segments = this.getSegmentsByConf(engineInputs);
			for (const segment of segments) {
				const valuesSegment = segment.data;
				const groupData = this.groupByProperty(valuesSegment, 'enginePosition');
				for (const key in groupData) {
					const values = groupData[key];
					const data = this.setYAxisData(values, _deltaSeconds * (parseInt(key) - 1));
					series[parseInt(key) - 1].data = [...series[parseInt(key) - 1].data, ...data];
				}
			}
			rectangles = this.getCurrentEnginesLine(engineInputs);
		}
		return [...series, ...rectangles];
	};

	getSegmentsByConf = (engineInputs) => {
		const groupDataByTime = this.groupByProperty(engineInputs, 'flightTime');
		const chartsData = [];
		Object.keys(groupDataByTime).map((keyDate) => {
			const values = groupDataByTime[keyDate];
			chartsData.push({
				date: keyDate,
				configuration: Array.from(new Set(values.sort((a, b) => a.enginePosition - b.enginePosition).map((r) => r.engineId))).join('/'),
				configurationNumbers: Array.from(new Set(values.sort((a, b) => a.enginePosition - b.enginePosition).map((r) => r?.engine.serialNumber))).join('/'),
				values: groupDataByTime[keyDate],
			});
		});

		const segments = [];

		for (const [index, data] of chartsData.entries()) {
			if (data.configuration != chartsData?.[index - 1]?.configuration) {
				segments.push({
					data: [...data.values],
					configuration: data.configuration,
				});
			} else {
				segments[segments.length - 1].data = [...segments[segments.length - 1].data, ...data.values];
			}
		}
		return segments;
	};

	getCurrentEnginesLine = (engineInputs) => {
		if (!this.enginesConfigurationItems.length) return [];
		const colors = {};
		const data = engineInputs.map((r) => ({ flightTime: r.flightTime, engineId: r.engineId, enginePosition: r.enginePosition }));

		const groupDataByTime = this.groupByProperty(data, 'flightTime');
		const chartsData = [];
		Object.keys(groupDataByTime).map((keyDate) => {
			const values = groupDataByTime[keyDate];
			chartsData.push({
				date: keyDate,
				configuration: Array.from(new Set(values.sort((a, b) => a.enginePosition - b.enginePosition).map((r) => r.engineId))).join('/'),
			});
		});

		const defaultProps = {
			animation: false,
			showSymbol: false,
			hoverAnimation: false,
			itemStyle: {
				color: 'rgba(255, 41, 184, 0)',
			},
			name: null,
			tooltip: {
				show: false,
			},
		};

		const maxValue = _.max(engineInputs.map((r) => r.value)).toFixed(3);
		const minValue = _.min(engineInputs.map((r) => r.value)).toFixed(3);
		const charts = {
			min: [],
			max: [],
		};
		const currentConfiguration = this.configuration?.configuration;

		for (const [index, data] of chartsData.entries()) {
			if (data.configuration != chartsData?.[index - 1]?.configuration) {
				if (charts.max.length) {
					const dateLength = charts.max[charts.max.length - 1].data.length;
					if (dateLength > 1) {
						charts.max[charts.max.length - 1].data[dateLength - 1] = [data.date, maxValue];
					} else {
						const oldValue = charts.max[charts.max.length - 1].data[dateLength - 1];
						charts.max[charts.max.length - 1].data[dateLength - 1] = [data.date, maxValue];
						charts.max[charts.max.length - 1].data.push(oldValue);
					}
				}
				if (charts.min.length) {
					const dateLength = charts.min[charts.min.length - 1].data.length;
					if (dateLength > 1) {
						charts.min[charts.min.length - 1].data[dateLength - 1] = [data.date, minValue];
					} else {
						const oldValue = charts.min[charts.min.length - 1].data[dateLength - 1];
						charts.min[charts.min.length - 1].data[dateLength - 1] = [data.date, minValue];
						charts.min[charts.min.length - 1].data.push(oldValue);
					}
				}
				const color = currentConfiguration === data.configuration ? '#FFFFFF' : colors[data.configuration] || generateColor(index);
				if (maxValue >= 0) {
					charts.max.push({
						type: 'line',
						data: [[data.date, maxValue]],
						configuration: data.configuration,
						...defaultProps,
						areaStyle: {
							color: color,
							opacity: 0.3,
						},
					});
				}
				if (minValue < 0) {
					charts.min.push({
						type: 'line',
						data: [[data.date, minValue]],
						configuration: data.configuration,
						...defaultProps,
						areaStyle: {
							color: color,
							opacity: 0.3,
						},
					});
				}
				if (!colors[data.configuration]) colors[data.configuration] = color;
			} else {
				if (charts.max.length) charts.max[charts.max.length - 1].data.push([data.date, maxValue]);
				if (charts.min.length) charts.min[charts.min.length - 1].data.push([data.date, minValue]);
			}
		}
		return [...charts.max, ...charts.min];
	};

	groupByProperty = (data, property) => {
		const groups = data.reduce((acc, current, i) => {
			if (current[property]) {
				const key = current[property];

				if (!acc[key]) {
					acc[key] = [];
				}

				acc[key].push(current);
			}

			return acc;
		}, {});

		return groups;
	};

	setYAxisData = (data, delta = 0) => {
		let smoothedOld = null;
		const result = data.map((item) => {
			let _flightTime = this.isBombardie ? item.flightTime.replace('Z', '') : item.flightTime;
			_flightTime = new Date(_flightTime);
			_flightTime = new Date(_flightTime.setSeconds(_flightTime.getSeconds() + delta));

			if (smoothedOld === null && this.isSmoothed) {
				smoothedOld = item.value;
			} else if (this.isSmoothed) {
				smoothedOld = smoothedOld + this.smoothingCoefficient * (item.value - smoothedOld);
			} else {
				smoothedOld = item.value;
			}
			return [_flightTime || item.flightTime, smoothedOld?.toFixed(3)];
		});
		smoothedOld = null;
		return result;
	};

	onFlightPhaseChange = async (value) => {
		this.isChartsLoad = true;
		this.flightPhase = value;
		await this.setParamsAndFlightPhase();
		await this.loadLocalStorage();
		this.localStorage.flightPhase = this.flightPhase;
		store.local.save();
		await this.reloadCharts();
		this.isChartsLoad = false;
	};

	getParameterName = (param) => t(`parameter.${param.code}`, { lng: store.mergedConfig?.language || 'en' });

	loadLocalStorage = async () => {
		const paramCodes = [...this.cache.get(this.flightPhase.phaseName).engineParams, ...this.cache.get(this.flightPhase.phaseName).aircraftParams].map(
			(item) => item.code
		);

		this.startDate = this.localStorage.startDate && new Date(this.localStorage.startDate);
		this.endDate = this.localStorage.endDate && new Date(this.localStorage.endDate);
		if (this.localStorage.colorsChart) this.colorsChart = this.localStorage.colorsChart;

		if (!this.localStorage.selectedParams[this.flightPhase.phaseName]) {
			this.localStorage.selectedParams[this.flightPhase.phaseName] = {};
		}
		const values = Object.values(this.localStorage.selectedParams[this.flightPhase.phaseName]).filter((item) => paramCodes.includes(item.code));

		const newMap = new Map();
		const promises = values.map((value) => {
			let param = new store.model.Parameter(value);
			newMap.set(param.code, { param });
			return this.getChartsAndTitle(param);
		});

		this.selectedParams = newMap;

		this.charts = await this.getCharts(promises);
	};

	//*-------------------------------------------------EXPORT PDF--------------------------------------------------------

	loadImage = (src) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = src;
		});
	};

	getChartImage = (chart) => {
		return this.loadImage(chart.getDataURL());
	};

	downloadReportAsPDF = async () => {
		try {
			const customPdfContent = this.pdfReportRef.current;

			html2canvas(customPdfContent, {
				allowTaint: false,
				useCORS: true,
				onclone: (document) => {
					const el = document.getElementById('pdf-export');
					el.style.display = 'block';
				},
			}).then(async (canvas) => {
				const dataURL = canvas.toDataURL();

				const pdf = new jsPDF({
					unit: 'px',
				});
				pdf.__padding = 20;

				pdf.setFontSize(10);
				pdf.setFont('Roboto-Regular', 'normal');

				const pdfWidth = pdf.internal.pageSize.getWidth();
				const pdfHeight = pdf.internal.pageSize.getHeight();
				const imageWidth = pdfWidth - 2 * pdf.__padding;
				const imageHeight = (imageWidth * canvas.height) / canvas.width;

				pdf.__lastYItemPosition = imageHeight;

				pdf.addImage(dataURL, 'JPEG', pdf.__padding, pdf.__padding, imageWidth, imageHeight);

				pdf.line(0, pdf.__lastYItemPosition + 20, pdfWidth, pdf.__lastYItemPosition + 20);

				pdf.__lastYItemPosition += 40;

				const images = [];
				for (const [key, { chartRef, title, ...rest }] of this.chartRefArray) {
					const instance = chartRef?.getEchartsInstance();
					if (instance) {
						const chartImg = await this.getChartImage(instance);
						const dpr = instance.getDevicePixelRatio();
						images.push({ chartImg, dpr, title, ...rest });
					}
				}

				const pageWidth = pdf.internal.pageSize.getWidth();
				const pageHeight = pdf.internal.pageSize.getHeight();

				const chartWidth = 300;
				const chartHeight = 150;

				images.forEach(({ chartImg, title, boundaries }, i) => {
					if (pdf.__lastYItemPosition + chartHeight > pdfHeight) {
						pdf.addPage();
						pdf.__lastYItemPosition = pdf.__padding;
					}

					pdf.text(`${t(title, { lng: 'en' })}\n[${boundaries[0] || ''} : ${boundaries[1] || ''}]`, pdf.__padding, pdf.__padding + pdf.__lastYItemPosition, {
						maxWidth: pageWidth - pdf.__padding - chartWidth - 20,
					});

					pdf.addImage(chartImg.src, 'PNG', pageWidth - chartWidth - 10, pdf.__lastYItemPosition, chartWidth, chartHeight);
					pdf.__lastYItemPosition += chartHeight;
				});

				const fileName = `graph_${this.aircraft.serialNumber}_${this.flightPhase.phaseName}_${format(new Date(), 'yyyyMMdd-HHmss')}.pdf`;

				await pdf.save(fileName, {
					returnPromise: true,
				});
			});

			// при скачивание отчета, создается запись в журнале (о просмотре отчета)
			const instance = {
				serialNumber: this.aircraft.serialNumber,
				phase: this.flightPhase.phaseName,
			};
			this.startDate && (instance.dateStart = format(new Date(this.startDate), 'yyyy-MM-dd'));
			this.endDate && (instance.dateEnd = format(new Date(this.endDate), 'yyyy-MM-dd'));
			const newAuditItem = new store.model.Audit({
				action: 'graphicReportPdfDownload',
				model: 'Audit',
				instance,
			});
			newAuditItem.save();
		} catch (e) {
			console.error('failed to export', e);
		}
	};
}
